import React from 'react';
import { Element } from 'react-scroll';
import DataTalentSectionTitle from './DataTalentSectionTitle';
// import { Link } from 'react-router-dom';

const DataTalentDescription = ({
  setShowEmployerRegisterForm,
  setShowUserInterestForm
}) => {
  return (
    <>
      <Element
        name="introduction"
        className="data-talent-description-content service-wrapper-1 style-variation3 blog-main-content justify-content-center edu-section-gap" id="DataTalentIntroduction"
      >
        <div className="container eduvibe-animated-shape">
          <div className="row g-5">
            <div className="col-lg-12">
              <DataTalentSectionTitle
                classes = "text-center"
                title = "Expand your Talent Pipeline with Skilled Data Professionals"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-5 d-flex align-items-center justify-content-center">
              <img src={`${process.env.PUBLIC_URL}/images/sponsors/riipen+m2m_logo.svg`} alt="Riipen and M2M logo" />
            </div>
            <div className="col-lg-7 mt--40 data-talent-description">
              <p>The DataTalent program offers Canadian employers the opportunity to connect with outstanding data professionals through fully subsidized internships and projects. This engagement not only benefits employers but also plays a role in fostering a resilient workforce. This collaborative model, delivered in partnership with Riipen, has demonstrated its effectiveness in helping employers overcome barriers to hiring new talent, tackling immediate business challenges, expanding their capabilities, and enhancing their brand presence.</p>
            </div>
          </div>
          <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
            <div className="shape-image shape-image-1">
              <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-03-01.png`} alt="Shape Thumb" loading="lazy" />
            </div>
            <div className="shape-image shape-image-2">
              <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-08.png`} alt="Shape Thumb" loading="lazy" />
            </div>
            <div className="shape-image shape-image-4">
              <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-03-02.png`} alt="Shape Thumb" loading="lazy" />
            </div>
          </div>
        </div>
        
      </Element>
    </>
  )
}

export default DataTalentDescription;