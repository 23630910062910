import React from 'react';
import { Link } from 'react-router-dom';
import DataTalentSectionTitle from './DataTalentSectionTitle';

const items = [
  {
    id: 1,
    title: 'AI Prototype for Public Sector',
    info: 'The goal of the project is to build a prototype of an AI-based tool that can be used by public sector organizations to predict service needs based on client characteristics and results.',
    numberOfCourses: '12 weeks',
    modeOfCourses: 'Anywhere',
    category: 'Data Science, ML & AI',
    image: 'project-details/public_sector_AI_v2_thumbnail.webp',
    link: '/programs/industry-work-integrated-learning/ai-prototype-for-public-sector'
  },
  {
    id: 2,
    title: 'Artificial intelligence for speech enhancement',
    info: 'This project aims to use data collection with field work to create a model to improve the performance of RMK mics, a device used to receive and deliver sound.',
    numberOfCourses: '12 weeks',
    modeOfCourses: 'Anywhere',
    category: 'Data Science, ML & AI',
    image: 'project-details/AI_speech_enhancement_v2_thumbnail.webp',
    link: '/programs/industry-work-integrated-learning/artificial-intelligence-for-speech-enhancement'
  },
  {
    id: 3,
    title: 'Convert Chat Threads into a Vector Database',
    info: 'The goal of this project is to apply the latest AI & ML techniques to an existing dataset to create an AI/ML model that supports development of more content and user access with the platform\'s evolution.',
    numberOfCourses: '12 weeks',
    modeOfCourses: 'Anywhere',
    category: 'Data Science, ML & AI',
    image: 'project-details/AIChat_db_thumbnail.webp',
    link: '/programs/industry-work-integrated-learning/convert-chat-threads-into-a-vector-database'
  },
  {
    id: 4,
    title: 'AI & ML for Sports Data Site',
    info: 'Development of an AI / ML model related to projecting player and team performance, or findings other insights in the data so that it can be added to the data directory in SportWise.',
    numberOfCourses: '12 weeks',
    modeOfCourses: 'Anywhere',
    category: 'Data Science, ML & AI',
    image: 'project-details/AI_sports_data_thumbnail.webp',
    link: '/programs/industry-work-integrated-learning/aritificial-intelligence-and-machine-learning-for-sports-data-site'
  }
];

const DataTalentIndustryPartnerWIL = () => {
  return (
    <>
      <div className="edu-service-area service-wrapper-1 edu-section-gap bg-image" id="Work_Integrated_Learning">
        <div className="container eduvibe-animated-shape">
          <div className="row g-5">
            <div className="col-12">
              <DataTalentSectionTitle
                classes="text-center"
                title="Industry Partner Work Integrated Learning"
              />
            </div>
            { items.map( ( data , i ) => (
              <div key={data.id} className="col-lg-6 col-md-6 col-sm-6 col-12">
                <div className="service-card program-service-card service-card-1 radius-small">
                  <div className="inner">
                    <div className="thumbnail">
                      <a href={ data.link }>
                        <img src={`${process.env.PUBLIC_URL}/images/${ data.image }`} alt="Category Thumb" loading="lazy" />
                      </a>
                    </div>
                    <div className="content program-info">
                      <div className="program-info-content d-flex justify-content-between">
                        <span className="course-total"><i className="icon-time-line"></i> { data.numberOfCourses }</span>
                        <span className="course-total"><i className="icon-presentation"></i> { data.category }</span>
                      </div>
                      <h6 className="title mt-3"><a href={ data.link }>{ data.title }</a></h6>
                      <p className="description">{ data.info }</p>
                      <div className="d-flex justify-content-between flex-wrap mt-3">
                        {data.form ? <a className="edu-btn btn-secondary mt-3" href="https://form.jotform.com/m2mtech/wil-programs-sign-up">Register Now<i className="icon-arrow-right-line-right"></i></a> : <a href="/" style={{visibility : "hidden"}}>No link</a>}
                        <Link className="edu-btn btn-bg-alt mt-3" to={ data.link }>Find Out More<i className="icon-arrow-right-line-right"></i></Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) ) }
          </div>
        </div>
      </div>
    </>
  )
}

export default DataTalentIndustryPartnerWIL;