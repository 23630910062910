import React from 'react';
import { Element } from 'react-scroll';

const DataTalentDoneMissOut = () => {
  return (
    <>
      <Element
        name="DontMissOut"
        className="edu-newsletter-area newsletter-style-1 edu-section-gap service-wrapper-1 bg-color-primary"
      >
        <div className="container about-feature-list eduvibe-animated-shape">
          <div className="row g-5 align-items-center">
            <div className="col-lg-12">
              <div className="data-talent-riipen">
                <h4 className="text-center">Don't Miss Out on Exceptional DataTalent!</h4>
                <p>
                  Get in touch with us today to explore how our exceptional Data Science talent can make a difference in your projects. We're here to help you achieve your Data-driven goals efficiently and cost-effectively.
                </p>
                <div className="data-talent-cta mt--40">
                  <a className="edu-btn" href="https://form.jotform.com/m2mtech/employer-register">Employer Login</a>
                  <a className="edu-btn btn-secondary" href="https://form.jotform.com/m2mtech/cohort-sign-up">Jobseekers Login</a>
                </div>
              </div>
            </div>
          </div>
          <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
            <div className="shape-image shape-image-1">
              <img src="/images/shapes/shape-03-04.png" alt="Shape Thumb" />
            </div>
            <div className="shape-image shape-image-2">
              <img src="/images/shapes/shape-16.png" alt="Shape Thumb" />
            </div>
            <div className="shape-image shape-image-3">
              <img src="/images/shapes/shape-13-02.png" alt="Shape Thumb" />
            </div>
          </div>
        </div>
      </Element>
    </>
  )
}

export default DataTalentDoneMissOut;