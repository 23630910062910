import React, { useEffect, useState } from 'react';
import { Element } from 'react-scroll';
import RegulatoryDashboardSectionTitle from './RegulatoryDashboardSectionTitle';
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, 
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend } from "chart.js";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { ProductServices } from './ProductServices';
import { FilterMatchMode } from 'primereact/api';
import { Dropdown } from 'primereact/dropdown';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { scrollIntoViewWithOffset } from '../../utils/scrollToViewWithOffset';
import Checkbox from '../../common/Checkbox';

ChartJS.register(CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend);

const RegulatoryDashboard = ({
  setShowAddEntityForm,
  setShowAddReviewToEntityForm,
  setEntityName,
  formSubmittedSuccessfully,
  setFormSubmittedSuccessfully,
  addReviewToEntityFormSubmittedSuccessfully,
  setAddReviewToEntityFormSubmittedSuccessfully,
}) => {
  const [totalEntities, setTotalEntities] = useState();
  const [highRiskEntities, setHighRiskEntities] = useState();
  const [mediumRiskEntities, setMediumRiskEntities] = useState();
  const [lowRiskEntities, setLowRiskEntities] = useState();
  const [allEntities, setAllEntities] = useState([]);
  const [filteredEntities, setFilteredEntities] = useState([]);
  const [filterApplied, setFilterApplied] = useState(false);
  const [classificationSelectValue, setClassificationSelectValue] = useState();
  const [infractionSelectValue, setInfractionSelectValue] = useState();
  const [sentimentSelectValue, setSentimentSelectValue] = useState();
  const [inspectionSelectValue, setInspectionSelectValue] = useState();
  const [complaintSelectValue, setComplaintSelectValue] = useState();
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    score: { value: null, matchMode: FilterMatchMode.EQUALS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  // const [riskClassificationHigh, setRiskClassificationHigh] = useState(false);
  // const [riskClassificationMedium, setRiskClassificationMedium] = useState(false);
  // const [riskClassificationLow, setRiskClassificationLow] = useState(false);
  // const [infractionSeverityMajor, setInfractionSeverityMajor] = useState(false);
  // const [infractionSeverityMinor, setInfractionSeverityMinor] = useState(false);
  // const [infractionSeverityNone, setInfractionSeverityNone] = useState(false);
  // const [complaintSeverityMajor, setComplaintSeverityMajor] = useState(false);
  // const [complaintSeverityMinor, setComplaintSeverityMinor] = useState(false);
  // const [complaintSeverityNone, setComplaintSeverityNone] = useState(false);
  // const [sentimentAnalysisNone, setSentimentAnalysisNone] = useState(false);
  // const [sentimentAnalysisFlagged, setSentimentAnalysisFlagged] = useState(false);
  // const [inspectionResultFail, setInspectionResultFail] = useState(false);
  // const [inspectionResultPass, setInspectionResultPass] = useState(false);
  // const [inspectionResultNone, setInspectionResultNone] = useState(false);
  const [applyFilters, setApplyFilters] = useState({
    riskClassification: new Set(),
    infractionSeverity: new Set(),
    complaintSeverity: new Set(),
    sentimentAnalysis: new Set(),
    inspectionResult: new Set(),
  })

  const attributes = {
    riskClassification: ["High", "Medium", "Low"],
    infractionSeverity: ["major", "minor", "none"],
    complaintSeverity: ["major", "minor", "none"],
    sentimentAnalysis: ["flagged", "none"],
    inspectionResult: ["fail", "pass", "none"]
  }

  const [loading, setLoading] = useState(false);
  const [expandedRows, setExpandedRows] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const getEntitiesAPI = process.env.REACT_APP_GET_ENTITIES;
  const getEntityAPI = process.env.REACT_APP_GET_ENTITY;

  const [classifications] = useState(['High', 'Medium', 'Low']);
  const classificationOptions = [
    { value: "High", label: "High"},
    { value: "Medium", label: "Medium"},
    { value: "Low", label: "Low"},
  ];
  const infractionOptions = [
    { value: "none", label: "None"},
    { value: "minor", label: "Minor"},
    { value: "major", label: "Major"},
  ]
  const quarterComplaintSeverityOptions = [
    { value: "none", label: "None"},
    { value: "minor", label: "Minor"},
    { value: "major", label: "Major"},
  ]
  const quarterSentimentAnalysisOptions = [
    { value: "none", label: "None"},
    { value: "flagged", label: "Flagged"},
  ]
  const inspectionResultOptions = [
    { value: "none", label: "None"},
    { value: "pass", label: "Pass"},
    { value: "fail", label: "Fail"},
  ]

  const handleChangeClassification = (selected) => {
    if(selected) {
      setClassificationSelectValue(selected);
      setInfractionSelectValue(null);
      setSentimentSelectValue(null);
      setInspectionSelectValue(null);
      setComplaintSelectValue(null);
      let temp = allEntities;
      let filtered;
      if(selected.value === "High") {
        filtered = temp.filter((entity) => entity.risk.score > 12);
      } else if(selected.value === "Medium") {
        filtered = temp.filter((entity) => entity.risk.score >= 7 && entity.risk.score <= 12);
      } else if(selected.value === "Low") {
        filtered = temp.filter((entity) => entity.risk.score < 7 && entity.risk.score !== null);
      }
      setFilterApplied(true);
      setFilteredEntities(filtered);
    } else {
      setFilterApplied(false);
      setFilteredEntities([]);
      setClassificationSelectValue();
    }
  }

  const handleChangeInfraction = (selected) => {
    if(selected) {
      setInfractionSelectValue(selected);
      setClassificationSelectValue(null);
      setSentimentSelectValue(null);
      setInspectionSelectValue(null);
      setComplaintSelectValue(null);
      let temp = allEntities;
      let filtered = temp.filter((entity) => entity.risk.recent_review?.infraction_severity === selected.value);
      setFilterApplied(true);
      setFilteredEntities(filtered);
    } else {
      setFilterApplied(false);
      setFilteredEntities([]);
      setInfractionSelectValue();
    }
  }
  
  const handleChangeComplaint = (selected) => {
    if(selected) {
      setComplaintSelectValue(selected);
      setClassificationSelectValue(null);
      setSentimentSelectValue(null);
      setInspectionSelectValue(null);
      setInfractionSelectValue(null);
      let temp = allEntities;
      let filtered = temp.filter((entity) => entity.risk.recent_review?.quarter_complaint_severity === selected.value);
      setFilterApplied(true);
      setFilteredEntities(filtered);
    } else {
      setFilterApplied(false);
      setFilteredEntities([]);
      setComplaintSelectValue();
    }
  }
  
  const handleChangeSentiment = (selected) => {
    if(selected) {
      setSentimentSelectValue(selected);
      setClassificationSelectValue(null);
      setInspectionSelectValue(null);
      setInfractionSelectValue(null);
      setComplaintSelectValue(null);
      let temp = allEntities;
      let filtered = temp.filter((entity) => entity.risk.recent_review?.quarter_sentiment_analysis === selected.value);
      setFilterApplied(true);
      setFilteredEntities(filtered);
    } else {
      setFilterApplied(false);
      setFilteredEntities([]);
      setSentimentSelectValue();
    }
  }

  const handleChangeInspection = (selected) => {
    if(selected) {
      setInspectionSelectValue(selected);
      setClassificationSelectValue(null);
      setInfractionSelectValue(null);
      setComplaintSelectValue(null);
      setSentimentSelectValue(null);
      let temp = allEntities;
      let filtered = temp.filter((entity) => entity.risk.recent_review?.inspection_result === selected.value);
      setFilterApplied(true);
      setFilteredEntities(filtered);
    } else {
      setFilterApplied(false);
      setFilteredEntities([]);
      setInspectionSelectValue();
    }
  }
  
  // const handleChangeRiskClassificationHigh = () => {
  //   setRiskClassificationHigh(!riskClassificationHigh);
  // }

  // const handleChangeRiskClassificationMedium = () => {
  //   setRiskClassificationMedium(!riskClassificationMedium);
  // }

  // const handleChangeRiskClassificationLow = () => {
  //   setRiskClassificationLow(!riskClassificationLow);
  // }

  // const handleChangeInfractionSeverityMajor = () => {
  //   setInfractionSeverityMajor(!infractionSeverityMajor);
  // }

  // const handleChangeInfractionSeverityMinor = () => {
  //   setInfractionSeverityMinor(!infractionSeverityMinor);
  // }

  // const handleChangeInfractionSeverityNone = () => {
  //   setInfractionSeverityNone(!infractionSeverityNone);
  // }

  // const handleChangeComplaintSeverityMajor = () => {
  //   setComplaintSeverityMajor(!complaintSeverityMajor);
  // }

  // const handleChangeComplaintSeverityMinor = () => {
  //   setComplaintSeverityMinor(!complaintSeverityMinor);
  // }

  // const handleChangeComplaintSeverityNone = () => {
  //   setComplaintSeverityNone(!complaintSeverityNone);
  // }

  // const handleChangeSentimentAnalysisFlagged = () => {
  //   setSentimentAnalysisFlagged(!sentimentAnalysisFlagged);
  // }
  
  // const handleChangeSentimentAnalysisNone = () => {
  //   setSentimentAnalysisNone(!sentimentAnalysisNone);
  // }

  // const handleChangeInspectionResultFail = () => {
  //   setInspectionResultFail(!inspectionResultFail);
  // }

  // const handleChangeInspectionResultPass = () => {
  //   setInspectionResultPass(!inspectionResultPass);
  // }

  // const handleChangeInspectionResultNone = () => {
  //   setInspectionResultNone(!inspectionResultNone);
  // }

  const handleCheckboxChange = (attribute, value) => {
    setApplyFilters(prevFilters => {
      const updatedCategory = new Set(prevFilters[attribute]);
      if (updatedCategory.has(value)) {
        updatedCategory.delete(value);
      } else {
        updatedCategory.add(value);
      }
      return {
        ...prevFilters,
        [attribute]: updatedCategory
      };
    });
  }

  const resetFilters = () => {
    setApplyFilters({
      riskClassification: new Set(),
      infractionSeverity: new Set(),
      complaintSeverity: new Set(),
      sentimentAnalysis: new Set(),
      inspectionResult: new Set(),
    })
  }

  const filterAppliedEntities = allEntities.filter(item => {
    const review = item.risk.recent_review;
    return (
      (applyFilters.riskClassification.size === 0 || applyFilters.riskClassification.has(item.risk.riskClassification)) &&
      (applyFilters.infractionSeverity.size === 0 || applyFilters.infractionSeverity.has(review?.infraction_severity)) &&
      (applyFilters.complaintSeverity.size === 0 || applyFilters.complaintSeverity.has(review?.quarter_complaint_severity)) &&
      (applyFilters.sentimentAnalysis.size === 0 || applyFilters.sentimentAnalysis.has(review?.quarter_sentiment_analysis)) &&
      (applyFilters.inspectionResult.size === 0 || applyFilters.inspectionResult.has(review?.inspection_result))
    );
  });

  const handleFilterEntityFormSubmit = (e) => {
    e.preventDefault();
    // let temp = allEntities;
    // let filtered;
    // console.log("here", filtered);
    // if(riskClassificationHigh) {
    //   filtered = temp.filter((entity) => entity.risk.score > 12);
    //   if(riskClassificationMedium) {
    //     filtered = temp.filter((entity) => entity.risk.score > 6);
    //     if(riskClassificationLow) {
    //       filtered = temp.filter((entity) => entity.risk.score >= 0);
    //       if(infractionSeverityMajor) {
    //         filtered = temp.filter((entity) => entity.risk.score >= 0 ||
    //           entity.risk.recent_review?.infraction_severity === "major");
    //         if(infractionSeverityMinor) {
    //           filtered = temp.filter((entity) => entity.risk.score >= 0 ||
    //             entity.risk.recent_review?.infraction_severity === "major" ||
    //             entity.risk.recent_review?.infraction_severity === "minor");
    //           if(infractionSeverityNone) {
    //             filtered = temp.filter((entity) => entity.risk.score >= 0 ||
    //               entity.risk.recent_review?.infraction_severity === "major" ||
    //               entity.risk.recent_review?.infraction_severity === "minor" ||
    //               entity.risk.recent_review?.infraction_severity === "none");
    //             if(inspectionResultFail) {
    //               filtered = temp.filter((entity) => entity.risk.score >= 0 ||
    //                 entity.risk.recent_review?.infraction_severity === "major" ||
    //                 entity.risk.recent_review?.infraction_severity === "minor" ||
    //                 entity.risk.recent_review?.infraction_severity === "none" ||
    //                 entity.risk.recent_review?.inspection_result === "fail");
    //               if(inspectionResultPass) {
    //                 filtered = temp.filter((entity) => entity.risk.score >= 0 ||
    //                   entity.risk.recent_review?.infraction_severity === "major" ||
    //                   entity.risk.recent_review?.infraction_severity === "minor" ||
    //                   entity.risk.recent_review?.infraction_severity === "none" ||
    //                   entity.risk.recent_review?.inspection_result === "fail" ||
    //                   entity.risk.recent_review?.inspection_result === "pass");
    //                 if(inspectionResultNone) {
    //                   filtered = temp.filter((entity) => entity.risk.score >= 0 ||
    //                     entity.risk.recent_review?.infraction_severity === "major" ||
    //                     entity.risk.recent_review?.infraction_severity === "minor" ||
    //                     entity.risk.recent_review?.infraction_severity === "none" ||
    //                     entity.risk.recent_review?.inspection_result === "fail" ||
    //                     entity.risk.recent_review?.inspection_result === "pass" ||
    //                     entity.risk.recent_review?.inspection_result === "none");
    //                   if(complaintSeverityMajor) {
    //                     filtered = temp.filter((entity) => entity.risk.score >= 0 ||
    //                       entity.risk.recent_review?.infraction_severity === "major" ||
    //                       entity.risk.recent_review?.infraction_severity === "minor" ||
    //                       entity.risk.recent_review?.infraction_severity === "none" ||
    //                       entity.risk.recent_review?.inspection_result === "fail" ||
    //                       entity.risk.recent_review?.inspection_result === "pass" ||
    //                       entity.risk.recent_review?.inspection_result === "none" ||
    //                       entity.risk.recent_review?.quarter_complaint_severity === "major");
    //                     if(complaintSeverityMinor) {
    //                       filtered = temp.filter((entity) => entity.risk.score >= 0 ||
    //                         entity.risk.recent_review?.infraction_severity === "major" ||
    //                         entity.risk.recent_review?.infraction_severity === "minor" ||
    //                         entity.risk.recent_review?.infraction_severity === "none" ||
    //                         entity.risk.recent_review?.inspection_result === "fail" ||
    //                         entity.risk.recent_review?.inspection_result === "pass" ||
    //                         entity.risk.recent_review?.inspection_result === "none" ||
    //                         entity.risk.recent_review?.quarter_complaint_severity === "major" ||
    //                         entity.risk.recent_review?.quarter_complaint_severity === "minor");
    //                       if(complaintSeverityNone) {
    //                         filtered = temp.filter((entity) => entity.risk.score >= 0 ||
    //                           entity.risk.recent_review?.infraction_severity === "major" ||
    //                           entity.risk.recent_review?.infraction_severity === "minor" ||
    //                           entity.risk.recent_review?.infraction_severity === "none" ||
    //                           entity.risk.recent_review?.inspection_result === "fail" ||
    //                           entity.risk.recent_review?.inspection_result === "pass" ||
    //                           entity.risk.recent_review?.inspection_result === "none" ||
    //                           entity.risk.recent_review?.quarter_complaint_severity === "major" ||
    //                           entity.risk.recent_review?.quarter_complaint_severity === "minor" ||
    //                           entity.risk.recent_review?.quarter_complaint_severity === "none");
    //                         if(sentimentAnalysisFlagged) {
    //                           filtered = temp.filter((entity) => entity.risk.score >= 0 ||
    //                             entity.risk.recent_review?.infraction_severity === "major" ||
    //                             entity.risk.recent_review?.infraction_severity === "minor" ||
    //                             entity.risk.recent_review?.infraction_severity === "none" ||
    //                             entity.risk.recent_review?.inspection_result === "fail" ||
    //                             entity.risk.recent_review?.inspection_result === "pass" ||
    //                             entity.risk.recent_review?.inspection_result === "none" ||
    //                             entity.risk.recent_review?.quarter_complaint_severity === "major" ||
    //                             entity.risk.recent_review?.quarter_complaint_severity === "minor" ||
    //                             entity.risk.recent_review?.quarter_complaint_severity === "none" ||
    //                             entity.risk.recent_review?.quarter_sentiment_analysis === "flagged");
    //                           if(sentimentAnalysisNone) {
    //                             filtered = temp.filter((entity) => entity.risk.score >= 0 ||
    //                               entity.risk.recent_review?.infraction_severity === "major" ||
    //                               entity.risk.recent_review?.infraction_severity === "minor" ||
    //                               entity.risk.recent_review?.infraction_severity === "none" ||
    //                               entity.risk.recent_review?.inspection_result === "fail" ||
    //                               entity.risk.recent_review?.inspection_result === "pass" ||
    //                               entity.risk.recent_review?.inspection_result === "none" ||
    //                               entity.risk.recent_review?.quarter_complaint_severity === "major" ||
    //                               entity.risk.recent_review?.quarter_complaint_severity === "minor" ||
    //                               entity.risk.recent_review?.quarter_complaint_severity === "none" ||
    //                               entity.risk.recent_review?.quarter_sentiment_analysis === "flagged" ||
    //                               entity.risk.recent_review?.quarter_sentiment_analysis === "none");
    //                           }
    //                         } else {
    //                           if(sentimentAnalysisNone) {
    //                             filtered = temp.filter((entity) => entity.risk.score >= 0 ||
    //                               entity.risk.recent_review?.infraction_severity === "major" ||
    //                               entity.risk.recent_review?.infraction_severity === "minor" ||
    //                               entity.risk.recent_review?.infraction_severity === "none" ||
    //                               entity.risk.recent_review?.inspection_result === "fail" ||
    //                               entity.risk.recent_review?.inspection_result === "pass" ||
    //                               entity.risk.recent_review?.inspection_result === "none" ||
    //                               entity.risk.recent_review?.quarter_complaint_severity === "major" ||
    //                               entity.risk.recent_review?.quarter_complaint_severity === "minor" ||
    //                               entity.risk.recent_review?.quarter_complaint_severity === "none" ||
    //                               entity.risk.recent_review?.quarter_sentiment_analysis === "none");
    //                           }
    //                         }
    //                       } else {
    //                         if(sentimentAnalysisFlagged) {
    //                           filtered = temp.filter((entity) => entity.risk.score >= 0 ||
    //                             entity.risk.recent_review?.infraction_severity === "major" ||
    //                             entity.risk.recent_review?.infraction_severity === "minor" ||
    //                             entity.risk.recent_review?.infraction_severity === "none" ||
    //                             entity.risk.recent_review?.inspection_result === "fail" ||
    //                             entity.risk.recent_review?.inspection_result === "pass" ||
    //                             entity.risk.recent_review?.inspection_result === "none" ||
    //                             entity.risk.recent_review?.quarter_complaint_severity === "major" ||
    //                             entity.risk.recent_review?.quarter_complaint_severity === "minor" ||
    //                             entity.risk.recent_review?.quarter_sentiment_analysis === "flagged");
    //                           if(sentimentAnalysisNone) {
    //                             filtered = temp.filter((entity) => entity.risk.score >= 0 ||
    //                               entity.risk.recent_review?.infraction_severity === "major" ||
    //                               entity.risk.recent_review?.infraction_severity === "minor" ||
    //                               entity.risk.recent_review?.infraction_severity === "none" ||
    //                               entity.risk.recent_review?.inspection_result === "fail" ||
    //                               entity.risk.recent_review?.inspection_result === "pass" ||
    //                               entity.risk.recent_review?.inspection_result === "none" ||
    //                               entity.risk.recent_review?.quarter_complaint_severity === "major" ||
    //                               entity.risk.recent_review?.quarter_complaint_severity === "minor" ||
    //                               entity.risk.recent_review?.quarter_sentiment_analysis === "flagged" ||
    //                               entity.risk.recent_review?.quarter_sentiment_analysis === "none");
    //                           }
    //                         } else {
    //                           if(sentimentAnalysisNone) {
    //                             filtered = temp.filter((entity) => entity.risk.score >= 0 ||
    //                               entity.risk.recent_review?.infraction_severity === "major" ||
    //                               entity.risk.recent_review?.infraction_severity === "minor" ||
    //                               entity.risk.recent_review?.infraction_severity === "none" ||
    //                               entity.risk.recent_review?.inspection_result === "fail" ||
    //                               entity.risk.recent_review?.inspection_result === "pass" ||
    //                               entity.risk.recent_review?.inspection_result === "none" ||
    //                               entity.risk.recent_review?.quarter_complaint_severity === "major" ||
    //                               entity.risk.recent_review?.quarter_complaint_severity === "minor" ||
    //                               entity.risk.recent_review?.quarter_sentiment_analysis === "none");
    //                           }
    //                         }
    //                       }
    //                     } else {
    //                       if(complaintSeverityNone) {
    //                         filtered = temp.filter((entity) => entity.risk.score >= 0 ||
    //                           entity.risk.recent_review?.infraction_severity === "major" ||
    //                           entity.risk.recent_review?.infraction_severity === "minor" ||
    //                           entity.risk.recent_review?.infraction_severity === "none" ||
    //                           entity.risk.recent_review?.inspection_result === "fail" ||
    //                           entity.risk.recent_review?.inspection_result === "pass" ||
    //                           entity.risk.recent_review?.inspection_result === "none" ||
    //                           entity.risk.recent_review?.quarter_complaint_severity === "major" ||
    //                           entity.risk.recent_review?.quarter_complaint_severity === "none");
    //                         if(sentimentAnalysisFlagged) {
    //                           filtered = temp.filter((entity) => entity.risk.score >= 0 ||
    //                             entity.risk.recent_review?.infraction_severity === "major" ||
    //                             entity.risk.recent_review?.infraction_severity === "minor" ||
    //                             entity.risk.recent_review?.infraction_severity === "none" ||
    //                             entity.risk.recent_review?.inspection_result === "fail" ||
    //                             entity.risk.recent_review?.inspection_result === "pass" ||
    //                             entity.risk.recent_review?.inspection_result === "none" ||
    //                             entity.risk.recent_review?.quarter_complaint_severity === "major" ||
    //                             entity.risk.recent_review?.quarter_complaint_severity === "none" ||
    //                             entity.risk.recent_review?.quarter_sentiment_analysis === "flagged");
    //                           if(sentimentAnalysisNone) {
    //                             filtered = temp.filter((entity) => entity.risk.score >= 0 ||
    //                               entity.risk.recent_review?.infraction_severity === "major" ||
    //                               entity.risk.recent_review?.infraction_severity === "minor" ||
    //                               entity.risk.recent_review?.infraction_severity === "none" ||
    //                               entity.risk.recent_review?.inspection_result === "fail" ||
    //                               entity.risk.recent_review?.inspection_result === "pass" ||
    //                               entity.risk.recent_review?.inspection_result === "none" ||
    //                               entity.risk.recent_review?.quarter_complaint_severity === "major" ||
    //                               entity.risk.recent_review?.quarter_complaint_severity === "none" ||
    //                               entity.risk.recent_review?.quarter_sentiment_analysis === "flagged" ||
    //                               entity.risk.recent_review?.quarter_sentiment_analysis === "none");
    //                           }
    //                         } else {
    //                           if(sentimentAnalysisNone) {
    //                             filtered = temp.filter((entity) => entity.risk.score >= 0 ||
    //                               entity.risk.recent_review?.infraction_severity === "major" ||
    //                               entity.risk.recent_review?.infraction_severity === "minor" ||
    //                               entity.risk.recent_review?.infraction_severity === "none" ||
    //                               entity.risk.recent_review?.inspection_result === "fail" ||
    //                               entity.risk.recent_review?.inspection_result === "pass" ||
    //                               entity.risk.recent_review?.inspection_result === "none" ||
    //                               entity.risk.recent_review?.quarter_complaint_severity === "major" ||
    //                               entity.risk.recent_review?.quarter_complaint_severity === "none" ||
    //                               entity.risk.recent_review?.quarter_sentiment_analysis === "none");
    //                           }
    //                         }
    //                       } else {
    //                         if(sentimentAnalysisFlagged) {
    //                           filtered = temp.filter((entity) => entity.risk.score >= 0 ||
    //                             entity.risk.recent_review?.infraction_severity === "major" ||
    //                             entity.risk.recent_review?.infraction_severity === "minor" ||
    //                             entity.risk.recent_review?.infraction_severity === "none" ||
    //                             entity.risk.recent_review?.inspection_result === "fail" ||
    //                             entity.risk.recent_review?.inspection_result === "pass" ||
    //                             entity.risk.recent_review?.inspection_result === "none" ||
    //                             entity.risk.recent_review?.quarter_complaint_severity === "major" ||
    //                             entity.risk.recent_review?.quarter_sentiment_analysis === "flagged");
    //                           if(sentimentAnalysisNone) {
    //                             filtered = temp.filter((entity) => entity.risk.score >= 0 ||
    //                               entity.risk.recent_review?.infraction_severity === "major" ||
    //                               entity.risk.recent_review?.infraction_severity === "minor" ||
    //                               entity.risk.recent_review?.infraction_severity === "none" ||
    //                               entity.risk.recent_review?.inspection_result === "fail" ||
    //                               entity.risk.recent_review?.inspection_result === "pass" ||
    //                               entity.risk.recent_review?.inspection_result === "none" ||
    //                               entity.risk.recent_review?.quarter_complaint_severity === "major" ||
    //                               entity.risk.recent_review?.quarter_sentiment_analysis === "flagged" ||
    //                               entity.risk.recent_review?.quarter_sentiment_analysis === "none");
    //                           }
    //                         } else {
    //                           filtered = temp.filter((entity) => entity.risk.score >= 0 ||
    //                             entity.risk.recent_review?.infraction_severity === "major" ||
    //                             entity.risk.recent_review?.infraction_severity === "minor" ||
    //                             entity.risk.recent_review?.infraction_severity === "none" ||
    //                             entity.risk.recent_review?.inspection_result === "fail" ||
    //                             entity.risk.recent_review?.inspection_result === "pass" ||
    //                             entity.risk.recent_review?.inspection_result === "none" ||
    //                             entity.risk.recent_review?.quarter_complaint_severity === "major" ||
    //                             entity.risk.recent_review?.quarter_sentiment_analysis === "none");
    //                         }
    //                       }
    //                     }
    //                   }
    //                 }
    //               }
    //             }
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
    // console.log("sizes: " + filterAppliedEntities.length + " and : " + allEntities.length)
    if(filterAppliedEntities.length === allEntities.length) {
      setFilterApplied(false);
    } else {
      setFilterApplied(true);
      setFilteredEntities(filterAppliedEntities);
    }
  }

  const labels = ['High', 'Medium', 'Low'];

  const options = {
    indexAxis: 'y',
    scales: {
      y: {
        beginAtZero: true
      }
    },
    elements: {
      bar: {
        borderWidth: 0,
      },
    },
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: 'Risk Distribution'
      },
    },
  };

  const data = {
    labels: labels,
    datasets: [
      {
        label: "High Risk",
        barPercentage: 3,
        barThickenss: 90,
        backgroundColor: ["#FF0003"],
        borderColor: ["#FF0003"],
        data: [highRiskEntities, 0, 0],
      },
      {
        label: "Medium Risk",
        barPercentage: 2.5,
        barThickenss: 30,
        backgroundColor: ["#FF8F3C"],
        borderColor: ["#FF8F3C"],
        data: [0, mediumRiskEntities, 0],
      },
      {
        label: "Low Risk",
        barPercentage: 3,
        barThickenss: 30,
        backgroundColor: ["#3EB75E"],
        borderColor: ["#3EB75E"],
        data: [0, 0, lowRiskEntities],
      }
    ]
  };

  const handleRefreshRiskScoreClick = (entityName, entityID) => {
    const requestOptions = {
      method: "GET"
    }
    setRefresh(true);
    fetch(`${getEntityAPI}/${entityName}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        if (!filterApplied) {
          const updatedEntities = allEntities.map(entity => {
            if (entity.id === entityID) {
              return result;
            } else {
              return entity;
            }
          });
          // console.log("updated Entities", updatedEntities);
          setAllEntities([...updatedEntities]);
        } else {
          const updatedEntities = allEntities.map(entity => {
            if (entity.id === entityID) {
              return result;
            } else {
              return entity;
            }
          });
          // console.log("updated Entities", updatedEntities);
          setAllEntities([...updatedEntities]);
          const updatedFilteredEntities = filteredEntities.map(entity => {
            if (entity.id === entityID) {
              return result;
            } else {
              return entity;
            }
          });
          // console.log("updated Entities", updatedEntities);
          setFilteredEntities([...updatedFilteredEntities]);
        }
        // setTimeout(() => {
          setRefresh(false);
        // }, 3000);
        toast.success("Risk score refreshed!");
      })
      .catch((error) => {
        // setTimeout(() => {
          setRefresh(false);
        // }, 3000);
        toast.error("Failed to refresh Risk score. Try again!");
        return console.error(error);
      });
    
  }

  const allowExpansion = (rowData) => {
    return 1;
  };

  const rowExpansionTemplate = (data) => {
    console.log(data);
    if(!refresh) {
      return (
        <div className="entity-row-expansion row g-5 p-3">
          <div className="col-lg-12">
            <h5>Details</h5>
          </div>
          <div className="col-lg-6 mt-1">
            <p><strong>Name:</strong>&nbsp;&nbsp;{data.name}</p>
            <p><strong>Entity ID:</strong>&nbsp;&nbsp;{data.id}</p>
            <p><strong>Infraction History:</strong>&nbsp;&nbsp;{data.risk.infraction_history.replace("to", " to ")}</p>
          </div>
          <div className="col-lg-6 mt-1">
            <p><strong>Risk Score:</strong>&nbsp;&nbsp;{data.risk.score}</p>
            <div>
              <p className="d-inline-block"><strong>Risk Category:</strong> </p>&nbsp;&nbsp;&nbsp;
              {(data.risk.score >= 13 && data.risk.score <= 18) ?
                <div className="risk-classification-wrapper d-inline-block" value="High" severity="High">
                  <p className="risk-classification-high">High</p>
                </div>
              : (data.risk.score >= 7 && data.risk.score <= 12) ?
                <div className="risk-classification-wrapper d-inline-block" value="Medium" severity="Medium">
                  <p className="risk-classification-medium">Medium</p>
                </div>
              : (data.risk.score <= 6 && data.risk.score >= 1) ?
                <div className="risk-classification-wrapper d-inline-block" value="Low" severity="Low">
                  <p className="risk-classification-low">Low</p>
                </div>
              : <div className="risk-classification-wrapper d-inline-block" value="NA" severity="NA">
                  <p className="risk-classification-NA">N/A</p>
                </div>
              }
            </div>
          </div>
          <div className="col-lg-12 mt--20">
            <h5>Recent Review</h5>
          </div>
          {data.risk.recent_review ? <>
            <div className="col-lg-6 mt-1">
              <p><strong>Review Date:</strong>&nbsp;&nbsp;{new Date(data.risk.recent_review.date).toLocaleDateString('en-us', {
                weekday: "short",
                year: "numeric",
                month: "short",
                day: "numeric"})}</p>
              <p><strong>Inspection Result:</strong>&nbsp;&nbsp;{data.risk.recent_review.inspection_result.charAt(0).toUpperCase() + data.risk.recent_review.inspection_result.slice(1)}</p>
              <p><strong>Infraction Severity:</strong>&nbsp;&nbsp;{data.risk.recent_review.infraction_severity.charAt(0).toUpperCase() + data.risk.recent_review.infraction_severity.slice(1)}</p>
            </div>
            <div className="col-lg-6 mt-1">
              <p><strong>Quarter Complaint Severity:</strong>&nbsp;&nbsp;{data.risk.recent_review.quarter_complaint_severity.charAt(0).toUpperCase() + data.risk.recent_review.quarter_complaint_severity.slice(1)}</p>
              <p><strong>Quarter Sentiment Analysis:</strong>&nbsp;&nbsp;{data.risk.recent_review.quarter_sentiment_analysis.charAt(0).toUpperCase() + data.risk.recent_review.quarter_sentiment_analysis.slice(1)}</p>
              <p><strong>Annual Clients:</strong>&nbsp;&nbsp;{data.risk.recent_review.annual_clients}</p>
            </div>
          </> : <p className="mt-0">No recent review.</p>}
          <div className="col-lg-12 mt--20">
            <h5>Review History</h5>
          </div>
          <div className="col-lg-6 mt-1">
            {data.risk.reviews.length > 0 ? <ol className="mb-0">
              {data.risk.reviews.map((review, idx) => <li key={idx}><strong>{new Date(review.date).toLocaleDateString('en-us', {
              year: "numeric",
              month: "short",
              day: "numeric"})}</strong></li>)}
            </ol> : <p className="mt-0">No review.</p>}
          </div>
          <div className="col-lg-6 mt-1">
            <button style={{fontSize: "14px"}} className="edu-btn btn-small btn-bg-alt lh-1" onClick={() => handleRefreshRiskScoreClick(data.name, data.id)}>🔄 Refresh Risk Score</button><br></br>
            <button style={{fontSize: "14px"}} className="edu-btn btn-small btn-secondary lh-1 mt-3" onClick={() => {setShowAddReviewToEntityForm(true); setEntityName(data.name);}}>+ Add New Report</button>
          </div>
        </div>
      )
    } else {
      return <div className="mt-4">Refreshing data...<br></br><img src={`${process.env.PUBLIC_URL}/images/loading.gif`} alt="illustration" /></div>;
    }
  };
  
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  
  const renderHeader = () => {
    return (
      <div className="d-flex justify-content-end">
        <IconField iconPosition="left">
          <InputIcon className="pi pi-search" style={{fontFamily: "primeicons"}} />
          <InputText style={{paddingLeft: "2.5rem"}} value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
        </IconField>
      </div>
    );
  };

  const header = renderHeader();

  const formatDate = (value) => {
    return new Date(value).toLocaleDateString('en-us', {
      year: "numeric",
      month: "short",
      day: "numeric"});
  };

  const riskClassificationTemplate = (rowData) => {
    if(rowData.risk.score >= 13 && rowData.risk.score <= 18) {
      return (
        <div className="risk-classification-wrapper" value="High" severity="High">
          <p className="risk-classification-high">High</p>
        </div>
      )
    } else if(rowData.risk.score >= 7 && rowData.risk.score <= 12) {
      return (
        <div className="risk-classification-wrapper" value="Medium" severity="Medium">
          <p className="risk-classification-medium">Medium</p>
        </div>
      )
    } else if(rowData.risk.score <= 6 && rowData.risk.score >= 1) {
      return (
        <div className="risk-classification-wrapper" value="Low" severity="Low">
          <p className="risk-classification-low">Low</p>
        </div>
      )
    } else {
      return (
        <div className="risk-classification-wrapper" value="NA" severity="NA">
          <p className="risk-classification-NA">N/A</p>
        </div>
      )
    };
  };
  
  const dateBodyTemplate = (rowData) => {
    return rowData.risk.recent_review ? formatDate(rowData.risk.recent_review.date) : "";
  };

  const riskClassificationItemTemplate = (option) => {
    if(option === "High") {
      return (
        <div className="risk-classification-wrapper" value="High" severity="high">
          <p className="risk-classification-high">High</p>
        </div>
      )
    } else if(option === "Medium") {
      return (
        <div className="risk-classification-wrapper" value="Medium" severity="medium">
          <p className="risk-classification-medium">Medium</p>
        </div>
      )
    } else if(option === "Low") {
      return (
        <div className="risk-classification-wrapper" value="low" severity="low">
          <p className="risk-classification-low">Low</p>
        </div>
      )
    } else {
      return (
        <div className="risk-classification-wrapper" value="NA" severity="NA">
          <p className="risk-classification-NA">N/A</p>
        </div>
      )
    };
  };

  const infractionHistoryTemplate = (rowData) => {
    // console.log(rowData);
    return (
      <p className="mb-0">{rowData.risk.infraction_history.replace("to", " to ").toString()}</p>
    )
  }

  const riskClassificationFilterTemplate = (options) => {
    // console.log(options);
    return (
      <Dropdown value={options.value} options={classifications} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={riskClassificationItemTemplate} placeholder="Select One" className="p-column-filter" showClear />
    );
  };

  const handleRiskClassification = (obj) => {
    // handleChangeClassification(obj);
    scrollIntoViewWithOffset("RegulatoryComplianceTable");
  };

  useEffect(() => {
    // ProductServices.getProducts().then(data => {setAllEntities(data.entities); setTotalEntities(data.size);});
    setLoading(true);
    fetch(getEntitiesAPI, {
      method: "GET",      
    }).then((res) => {
      return res.json();
    }).then((data) => {
      const temp = data.entities.map((entity) => {
        if(entity.risk.score <= 6) {
          return ({ ...entity, risk: { ...entity.risk, riskClassification : "Low" }});
        } else if(entity.risk.score >= 7 && entity.risk.score <= 12) {
          return ({ ...entity, risk: { ...entity.risk, riskClassification : "Medium" }});
        } else if(entity.risk.score >= 13) {
          return ({ ...entity, risk: { ...entity.risk, riskClassification : "High" }});
        } else {
          return ({ ...entity, risk: { ...entity.risk, riskClassification : "N/A" }});
        }
      })
      console.log(temp);
      setAllEntities(temp);
      setTotalEntities(data.size);
      setLoading(false);
    })
  }, [getEntitiesAPI]);

  useEffect(() => {
    if(allEntities) {
      setHighRiskEntities(0);
      setMediumRiskEntities(0);
      setLowRiskEntities(0);
      allEntities.forEach((entity) => {
        if(entity.risk.score >= 13) {
          setHighRiskEntities(highRiskEntities => parseInt(highRiskEntities) + 1);
        } else if(entity.risk.score >= 7 && entity.risk.score <= 12) {
          setMediumRiskEntities(mediumRiskEntities => parseInt(mediumRiskEntities) + 1);
        } else {
          setLowRiskEntities(lowRiskEntities => parseInt(lowRiskEntities) + 1);
        }
      })
    }
  }, [allEntities])

  useEffect(() => {
    if(formSubmittedSuccessfully) {
      setLoading(true);
      fetch(getEntitiesAPI, {
        method: "GET",      
      }).then((res) => {
        return res.json();
      }).then((data) => {
        setAllEntities(data.entities);
        setTotalEntities(data.size);
        setLoading(false);
      })
      setFormSubmittedSuccessfully(false);
    }
  }, [getEntitiesAPI, formSubmittedSuccessfully, setFormSubmittedSuccessfully])

  useEffect(() => {
    if(addReviewToEntityFormSubmittedSuccessfully) {
      // setLoading(true);
      fetch(getEntitiesAPI, {
        method: "GET",      
      }).then((res) => {
        return res.json();
      }).then((data) => {
        setAllEntities(data.entities);
        setTotalEntities(data.size);
        // setLoading(false);
      })
      setAddReviewToEntityFormSubmittedSuccessfully(false);
    }
  }, [getEntitiesAPI, addReviewToEntityFormSubmittedSuccessfully, setAddReviewToEntityFormSubmittedSuccessfully])

  return (
    <>
      <Element
        name="healthcareInsights" 
        className="edu-about-area about-style-1 edu-section-gap"
      >
        <div className="container inner about-feature-list">
          <div className="row g-5 justify-content-center align-items-center">
            <div className="col-lg-12">
              <RegulatoryDashboardSectionTitle
                classes = "text-left"
                // slogan = "Eligibility"
                title = "Regulatory Compliance Dashboard"
              />
            </div>
            {loading ? <div className="mt-4">Loading data...<br></br><img src={`${process.env.PUBLIC_URL}/images/loading.gif`} alt="illustration" /></div> : <>
              <div className="col-lg-6">
                <h5>Entity Distribution</h5>
                <table id="EntityDistribution" className="col-12 col-lg-6">
                  <tbody>
                    <tr onClick={() => {scrollIntoViewWithOffset("RegulatoryComplianceTable"); resetFilters(); handleCheckboxChange("riskClassification", "High"); setShowFilters(true); setTimeout(() => {document.getElementById("ApplyFiltersBtn")?.click();}, 200)}} className="highlight-table-row">
                      <td>
                        <strong>High Risk Entities:</strong>
                      </td>
                      <td><strong>{highRiskEntities}</strong></td>
                    </tr>
                    <tr onClick={() => {scrollIntoViewWithOffset("RegulatoryComplianceTable"); resetFilters(); handleCheckboxChange("riskClassification", "Medium"); setShowFilters(true); setTimeout(() => {document.getElementById("ApplyFiltersBtn")?.click();}, 200)}} className="highlight-table-row">
                      <td>
                        <strong>Medium Risk Entities:</strong>
                      </td>
                      <td><strong>{mediumRiskEntities}</strong></td>
                    </tr>
                    <tr onClick={() => {scrollIntoViewWithOffset("RegulatoryComplianceTable"); resetFilters(); handleCheckboxChange("riskClassification", "Low"); setShowFilters(true); setTimeout(() => {document.getElementById("ApplyFiltersBtn")?.click();}, 200)}} className="highlight-table-row">
                      <td>
                        <strong>Low Risk Entities:</strong>
                      </td>
                      <td><strong>{lowRiskEntities}</strong></td>
                    </tr>
                    <tr style={{borderWidth: "2px 0 0 0"}} onClick={() => scrollIntoViewWithOffset("RegulatoryComplianceTable")} className="highlight-table-row">
                      <td>
                        <strong>Total Entities:</strong>
                      </td>
                      <td><strong>{totalEntities}</strong></td>
                    </tr>
                  </tbody>
                </table>
                
              <h5 className="mt-4">Calculation of Risk Classification</h5>
                <table id="RiskClassification" className="col-12 col-lg-10">
                  <thead>
                    <tr>
                      <th>Risk Classification</th>
                      <th>Risk Score</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr onClick={() => {scrollIntoViewWithOffset("RegulatoryComplianceTable"); resetFilters(); handleCheckboxChange("riskClassification", "Low"); setShowFilters(true); setTimeout(() => {document.getElementById("ApplyFiltersBtn")?.click();}, 200)}} className="highlight-table-row">
                      <td>
                        <div className="risk-classification-wrapper">
                          <p className="risk-classification-low">Low</p>
                        </div>
                      </td>
                      <td>Entities with Risk Score below 7</td>
                    </tr>
                    <tr onClick={() => {scrollIntoViewWithOffset("RegulatoryComplianceTable"); resetFilters(); handleCheckboxChange("riskClassification", "Medium"); setShowFilters(true); setTimeout(() => {document.getElementById("ApplyFiltersBtn")?.click();}, 200)}} className="highlight-table-row">
                      <td>
                        <div className="risk-classification-wrapper">
                          <p className="risk-classification-medium">Medium</p>
                        </div>
                      </td>
                      <td>Entities with Risk Score 7 to 12</td>
                    </tr>
                    <tr onClick={() => {scrollIntoViewWithOffset("RegulatoryComplianceTable"); resetFilters(); handleCheckboxChange("riskClassification", "High"); setShowFilters(true); setTimeout(() => {document.getElementById("ApplyFiltersBtn")?.click();}, 200)}} className="highlight-table-row">
                      <td>
                        <div className="risk-classification-wrapper">
                          <p className="risk-classification-high">High</p>
                        </div>
                      </td>
                      <td>Entities with Risk Score above 12</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-lg-6">
                <div className="bar-chart-container">
                  <Bar options={options} data={data} />
                </div>
              </div>
              <div className="col-lg-12">
                <div className="table-header d-flex flex-row justify-content-between align-items-center">
                  <h6 className="m-0">Regulated Entities</h6>
                  <div className="entities-table-buttons d-flex flex-row align-items-center gap-4">
                    <button className="edu-btn btn-secondary lh-1 btn-small" onClick={() => setShowAddEntityForm(true)}>+ Add Entity</button>
                    <button className="edu-btn btn-bg-alt lh-1 btn-small" onClick={() => setShowFilters(!showFilters)}><img src={`${process.env.PUBLIC_URL}/images/icons/filter-solid.svg`} className="cursor-pointer" alt="filters" /></button>
                  </div>
                </div>
                {/* <div className="filters-container mt-4 d-flex flex-row gap-4" id="RegulatoryComplianceTable">
                  <Select
                    className="basic-single flex-grow-1"
                    classNamePrefix="select"
                    placeholder="Risk Classification"
                    isClearable="true"
                    isSearchable="true"
                    name="classification"
                    options={classificationOptions}
                    value={classificationSelectValue}
                    onChange={handleChangeClassification}
                  />
                  <Select
                    className="basic-single flex-grow-1"
                    classNamePrefix="select"
                    placeholder="Infraction Severity"
                    isClearable="true"
                    isSearchable="true"
                    name="infraction"
                    options={infractionOptions}
                    value={infractionSelectValue}
                    onChange={handleChangeInfraction}
                  />
                  <Select
                    className="basic-single flex-grow-1"
                    classNamePrefix="select"
                    placeholder="Complaint Severity"
                    isClearable="true"
                    isSearchable="true"
                    name="complaint"
                    options={quarterComplaintSeverityOptions}
                    value={complaintSelectValue}
                    onChange={handleChangeComplaint}
                  />
                  <Select
                    className="basic-single flex-grow-1"
                    classNamePrefix="select"
                    placeholder="Sentiment Analysis"
                    isClearable="true"
                    isSearchable="true"
                    name="sentiment"
                    options={quarterSentimentAnalysisOptions}
                    value={sentimentSelectValue}
                    onChange={handleChangeSentiment}
                  />
                  <Select
                    className="basic-single flex-grow-1"
                    classNamePrefix="select"
                    placeholder="Inspection Result"
                    isClearable="true"
                    isSearchable="true"
                    name="inspection"
                    options={inspectionResultOptions}
                    value={inspectionSelectValue}
                    onChange={handleChangeInspection}
                  />
                </div> */}
                
                {showFilters && <form className="filters-container mt-4" onSubmit={handleFilterEntityFormSubmit}>
                  {/* <h3 className="entity-filters-header">Filters</h3> */}
                  <div className="entity-filters-body row justify-content-center">
                    {/* <div className="col-12 col-lg-4 d-flex flex-column">
                      <h5 className="mt-4 mb-0">Risk Classification</h5>
                      <Checkbox
                        label="High"
                        value={riskClassificationHigh}
                        onChange={handleChangeRiskClassificationHigh}
                      />
                      <Checkbox
                        label="Medium"
                        value={riskClassificationMedium}
                        onChange={handleChangeRiskClassificationMedium}
                      />
                      <Checkbox
                        label="Low"
                        value={riskClassificationLow}
                        onChange={handleChangeRiskClassificationLow}
                      />
                    </div>
                    <div className="col-12 col-lg-4 d-flex flex-column">
                      <h5 className="mt-4 mb-0">Infraction Severity</h5>
                      <Checkbox
                        label="Major"
                        value={infractionSeverityMajor}
                        onChange={handleChangeInfractionSeverityMajor}
                      />
                      <Checkbox
                        label="Minor"
                        value={infractionSeverityMinor}
                        onChange={handleChangeInfractionSeverityMinor}
                      />
                      <Checkbox
                        label="None"
                        value={infractionSeverityNone}
                        onChange={handleChangeInfractionSeverityNone}
                      />
                    </div>
                    <div className="col-12 col-lg-4 d-flex flex-column">
                      <h5 className="mt-4 mb-0">Inspection Result</h5>
                      <Checkbox
                        label="Fail"
                        value={inspectionResultFail}
                        onChange={handleChangeInspectionResultFail}
                      />
                      <Checkbox
                        label="Pass"
                        value={inspectionResultPass}
                        onChange={handleChangeInspectionResultPass}
                      />
                      <Checkbox
                        label="None"
                        value={inspectionResultNone}
                        onChange={handleChangeInspectionResultNone}
                      />
                    </div>
                    <div className="col-12 col-lg-4 d-flex flex-column">
                      <h5 className="mt-5 mb-0">Quarter Complaint Severity</h5>
                      <Checkbox
                        label="Major"
                        value={complaintSeverityMajor}
                        onChange={handleChangeComplaintSeverityMajor}
                      />
                      <Checkbox
                        label="Minor"
                        value={complaintSeverityMinor}
                        onChange={handleChangeComplaintSeverityMinor}
                      />
                      <Checkbox
                        label="None"
                        value={complaintSeverityNone}
                        onChange={handleChangeComplaintSeverityNone}
                      />
                    </div>
                    <div className="col-12 col-lg-4 d-flex flex-column">
                      <h5 className="mt-5 mb-0">Quarter Sentiment Analysis</h5>
                      <Checkbox
                        label="Flagged"
                        value={sentimentAnalysisFlagged}
                        onChange={handleChangeSentimentAnalysisFlagged}
                      />
                      <Checkbox
                        label="None"
                        value={sentimentAnalysisNone}
                        onChange={handleChangeSentimentAnalysisNone}
                      />
                    </div> */}
                    {Object.keys(attributes).map(category => (
                      <div key={category} className="row">
                        <p className="mt-4 mb-0"><strong>{(category.charAt(0).toUpperCase() + category.slice(1)).replace(/([A-Z])/g, ' $1')}</strong></p>
                        {attributes[category].map(value => (
                          <div key={value} className="col-12 col-lg-4 entity-filter-label">
                            <Checkbox
                              // key={value}
                              label={value.charAt(0).toUpperCase() + value.slice(1)}
                              value={applyFilters[category].has(value)}
                              onChange={() => handleCheckboxChange(category, value)}
                            />
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                  <div className="entity-filters-footer d-flex flex-row justify-content-center mt-4">
                    <button className="edu-btn btn-primary btn-medium" type="submit" id="ApplyFiltersBtn">Apply Filters</button>
                  </div>
                </form>}
                <div className="card mt-4" id="RegulatoryComplianceTable">
                  <DataTable
                    value={filterApplied ? filteredEntities : allEntities}
                    paginator
                    sortMode="multiple"
                    removableSort
                    rows={25}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    tableStyle={{ minWidth: '50rem' }}
                    dataKey="id"
                    header={header}
                    filters={filters}
                    // filterDisplay="row"
                    // loading={loading}
                    globalFilterFields={['name', 'risk.score', 'risk.recent_review.annual_clients', 'risk.recent_review.date', 'risk.infraction_history']}
                    emptyMessage="No entities found."                  
                    expandedRows={expandedRows}
                    onRowToggle={(e) => setExpandedRows(e.data)}
                    rowExpansionTemplate={rowExpansionTemplate}
                    style={{fontSize: "16px"}}
                  >
                    <Column field="name" sortable header="Entity Name"></Column>
                    <Column field="risk.score" body={riskClassificationTemplate} showFilterMenu={false} header="Risk Classification" filter filterElement={riskClassificationFilterTemplate}></Column>
                    <Column field="risk.score" sortable header="Risk Score"></Column>
                    <Column field="risk.recent_review.annual_clients" sortable header="Annual Clients"></Column>
                    <Column field="risk.recent_review.date" body={dateBodyTemplate} header="Recent Review"></Column>
                    <Column field="risk.infraction_history" header="Infraction History" body={infractionHistoryTemplate}></Column>
                    <Column expander={allowExpansion} style={{ width: '5rem' }} />
                  </DataTable>
                </div>
              </div>
            </>}
          </div>
        </div>
      </Element>
    </>
  )
}

export default RegulatoryDashboard;