import React from 'react';
import { Element } from 'react-scroll';
import DataTalentSectionTitle from './DataTalentSectionTitle';
// import { Link } from 'react-router-dom';

const DataTalentWhyChooseUs = ({
  setShowEmployerRegisterForm,
  setShowUserInterestForm,
  setShowDownloadCurriculumForm,
}) => {
  return (
    <>
      <Element
        name="WhyChooseUs"
        className="about-style-1 data-talent-eligibility-section edu-section-gap home-one-cat"
      >
        <div className="container inner about-feature-list">
          <div className="row g-5">
            <div className="col-lg-12">
              <DataTalentSectionTitle
                classes = "text-center"
                title = "Why Choose Us?"
              />
            </div>
            <div className="courses-description-content mt--40">
              <p>The DataTalent program is a collaborative effort between M2M Tech, AiModels, and Riipen, offering a comprehensive technical upskilling initiative. It seamlessly combines employer-driven work-integrated learning projects, professional development prospects, and exposure to the industry.</p>
            </div>
            <div>
              <h4 className="text-center">Funded By</h4>
              <div className="row align-items-center">
                <a className="d-flex justify-content-center col-lg-6" href="https://paletteskills.org/upskill-canada"><img src={`${process.env.PUBLIC_URL}/images/logo/upskill_canada_logo_v3.png`} width="75%" alt="logo" loading="lazy" /></a>
                <a className="d-flex justify-content-center col-lg-6 py-4" href="https://www.canada.ca/en.html"><img src={`${process.env.PUBLIC_URL}/images/logo/govt_of_canada.svg`} width="50%" alt="logo" loading="lazy" /></a>
              </div>
              <h4 className="text-center mt--40">In Partnership With</h4>
              <div className="row align-items-center">
                <a className="d-flex justify-content-center col-lg-6 py-3" href="https://www.riipen.com"><img src={`${process.env.PUBLIC_URL}/images/logo/Riipen_logo.svg`} width="50%" height="auto" alt="logo" loading="lazy" /></a>
                {/* <a className="d-flex justify-content-center col-lg-6" href="https://hearherstories.com/lumen/"><img src={`${process.env.PUBLIC_URL}/images/logo/Diwo_logo_v2.png`} width="50%" alt="logo" loading="lazy" /></a> */}
                <a className="d-flex justify-content-center col-lg-6" href="https://aimodels.ca/"><img src={`${process.env.PUBLIC_URL}/images/logo/aimodels-grey-bg-lrg.png`} width="50%" alt="logo" loading="lazy" /></a>
              </div>
            </div>
            {/* <img src={`${process.env.PUBLIC_URL}/images/data-talent/Powered-by-all-partners.png`} alt="logos" /> */}
            <div className="our-feature mt--40">
              <div className="icon">
                <i className="icon-checkbox-circle-fill"></i>
              </div>
              <div className="feature-content">
                <h6 className="feature-title"><em>Unparalleled Talent Pool</em></h6>
                <p className="feature-description">We have a curated talent pool of upskilled Data Science professionals who are not just proficient but exceptional in their field. Our candidates have undergone rigorous training and practical experience, making them ready to tackle complex data-driven challenges.</p>
              </div>
            </div>
            <div className="our-feature mt--40">
              <div className="icon">
                <i className="icon-checkbox-circle-fill"></i>
              </div>
              <div className="feature-content">
                <h6 className="feature-title"><em>Tailored to Your Needs</em></h6>
                <p className="feature-description">We understand that every project is unique. Whether you require expertise in Machine Learning, Data Analysis, Predictive Modeling, or any other Data Science domain, we have the talent that fits your precise requirements.</p>
              </div>
            </div>
            <div className="our-feature mt--40">
              <div className="icon">
                <i className="icon-checkbox-circle-fill"></i>
              </div>
              <div className="feature-content">
                <h6 className="feature-title"><em>Fully Subsidized Work Integrated Learning</em></h6>
                <p className="feature-description">We offer a unique opportunity for Canadian employers to post 80-hour-long Work Integrated Learning projects. What's more, these projects are fully subsidized, meaning you get access to and evaluate top talent at free of cost.</p>
              </div>
            </div>
            <div className="our-feature mt--40">
              <div className="icon">
                <i className="icon-checkbox-circle-fill"></i>
              </div>
              <div className="feature-content">
                <h6 className="feature-title"><em>Seamless Integration</em></h6>
                <p className="feature-description">Our candidates are not just experts in Data Science, they're also trained on Professional Skills to seamlessly integrate into your projects and teams. Their ability to communicate complex ideas clearly and work collaboratively ensures easier onboarding.</p>
              </div>
            </div>
            <div className="our-feature mt--40">
              <div className="icon">
                <i className="icon-checkbox-circle-fill"></i>
              </div>
              <div className="feature-content">
                <h6 className="feature-title"><em>Immediate Impact</em></h6>
                <p className="feature-description">You don't have to wait for months for your projects to gain momentum. Our candidates are ready to hit the ground running, delivering immediate value to your organization.</p>
              </div>
            </div>
            <div className="our-feature mt--40">
              <div className="icon">
                <i className="icon-checkbox-circle-fill"></i>
              </div>
              <div className="feature-content">
                <h6 className="feature-title"><em>Cost-Effective Solutions</em></h6>
                <p className="feature-description">Hiring top Data Science talent can be expensive - but with our subsidized projects, you get access to try our exceptional talent pool at no cost.</p>
              </div>
            </div>
            <div className="our-feature mt--40">
              <div className="icon">
                <i className="icon-checkbox-circle-fill"></i>
              </div>
              <div className="feature-content">
                <h6 className="feature-title"><em>Diverse Skill Sets</em></h6>
                <p className="feature-description">Whether you need skills in Python, R, Machine Learning, Deep Learning, Data Visualization, or any other Data Science domain, we have Diverse talent to meet your needs.</p>
              </div>
            </div>
          </div>
          <div className="shape shape-6 about-parallax-2 d-xl-block d-none">
            <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-07.png`} alt="Shape Thumb" loading="lazy" />
          </div>
        </div>
      </Element>      
    </>
  )
}

export default DataTalentWhyChooseUs;