import React from 'react';
import SectionTitle from '../sectionTitle/SectionTitle';
import ScrollAnimation from 'react-animate-on-scroll';
import { DataTalentBlogData } from '../../DataTalentBlogData';


const DataTalentBlogs = () => {
    return (
        <>
          {/* <div className="edu-blog-area eduvibe-home-one-blog edu-section-gap bg-image bg-color-white"> */}
          <div className="edu-blog-area eduvibe-datatalent-blog edu-section-gap bg-image bg-color-white home-one-cat">
            <div className="container eduvibe-animated-shape">
              <div className="row g-5 align-items-center">
                <div className="col-lg-12">
                  <SectionTitle
                    classes = "text-center"
                    // slogan = "student spotlights"
                    title = "Blogs from Our DataTalent Participants!"
                  />
                </div>
              </div>
              <div className="row g-5 mt--30">
                { 
                  DataTalentBlogData.toReversed().map((item) => (
                    <ScrollAnimation 
                      animateIn="fadeInUp"
                      animateOut="fadeInOut"
                      animateOnce={true}
                      className="col-lg-6 col-sm-6 col-12"
                      key={ item.id } 
                    >
                      <div className="edu-blog blog-type-3 radius-small bg-color-white">
                        <div className="inner">
                          <div className="content">
                            <h4 className="title">
                              <a href={item.link}>{item.title}</a>
                            </h4>
                            <div className="blog-card-bottom">
                              <ul className="blog-meta">
                                <li><i className="icon-calendar-2-line"></i>{ item.date }</li>
                                <li><i className="icon-user-line"></i>Posted By <span className="color-primary">{item.author}</span></li>
                              </ul>
                            </div>
                          </div>
                          <div className="thumbnail">
                            <a href={item.link}>
                              <img src={`${process.env.PUBLIC_URL}/images/${item.image}`} alt="Blog Thumb" />
                            </a>
                          </div>
                          <div className="description">
                            <p style={{whiteSpaceCollapse: "preserve"}}>{ item.details }</p>
                          </div>
                        </div>
                      </div>
                    </ScrollAnimation>
                  ) )
                }
              </div>
              <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                <div className="shape-image shape-image-1">
                  <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-04-02.png`} alt="Shape Thumb" loading="lazy" />
                </div>
                <div className="shape-image shape-image-2">
                  <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-15-02.png`} alt="Shape Thumb" loading="lazy" />
                </div>
                <div className="shape-image shape-image-3">
                  <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-13-03.png`} alt="Shape Thumb" loading="lazy" />
                </div>
                <div className="shape-image shape-image-4">
                  <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-17.png`} alt="Shape Thumb" loading="lazy" />
                </div>
              </div>
            </div>
          </div>
        </>
    )
}

export default DataTalentBlogs;