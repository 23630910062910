import React from 'react';
import { Element } from 'react-scroll';
import SectionTitle from '../sectionTitle/SectionTitle';

const AboutUsTeam = () => {

  return (
    <>
    <Element
        name="about-us"
        className="service-wrapper-1 style-variation3 blog-main-content justify-content-center edu-section-gap"
      >
        <div className="container about-feature-list eduvibe-animated-shape">
          <div className="row g-5">
            <div className="col-12">
              <SectionTitle
                classes="text-center"
                title="Our Team"
                slogan="Meet our team"
              />
            </div>
            <div className="col-12">
              <img src={`${process.env.PUBLIC_URL}/images/team/team_lunch.png`} alt="people sitting in a restaurent" />
            </div>
          </div>
          {/* <div className="row g-5">
            <div className="col-12">
              <SectionTitle
                classes="text-center"
                title="Our Leadership Team"
                slogan="Meet our leaders"
              />
            </div>
            <div className="row g-5">
              <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt--45">
                <div className="edu-instructor-grid edu-instructor-2">
                  <div className="edu-instructor">
                    <div className="inner">
                      <div className="thumbnail">
                        <a href="#">
                          <img src="/images1/instructor/instructor-02/instructor-1.jpg" alt="team member" />
                        </a>
                      </div>
                      <div className="team-share-info">
                        <a href="#" className="linkedin">
                          <i className="icon-linkedin"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="edu-instructor-info">
                    <h5 className="title">Ashok Kasilingam</h5>
                    <span className="desc">Lead</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt--45">
                <div className="edu-instructor-grid edu-instructor-2">
                  <div className="edu-instructor">
                    <div className="inner">
                      <div className="thumbnail">
                        <a href="#">
                          <img src="/images1/instructor/instructor-02/instructor-1.jpg" alt="team member" />
                        </a>
                      </div>
                      <div className="team-share-info">
                        <a href="#" className="linkedin">
                          <i className="icon-linkedin"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="edu-instructor-info">
                    <h5 className="title">Ashok Kasilingam</h5>
                    <span className="desc">Lead</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt--45">
                <div className="edu-instructor-grid edu-instructor-2">
                  <div className="edu-instructor">
                    <div className="inner">
                      <div className="thumbnail">
                        <a href="#">
                          <img src="/images1/instructor/instructor-02/instructor-1.jpg" alt="team member" />
                        </a>
                      </div>
                      <div className="team-share-info">
                        <a href="#" className="linkedin">
                          <i className="icon-linkedin"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="edu-instructor-info">
                    <h5 className="title">Ashok Kasilingam</h5>
                    <span className="desc">Lead</span>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
            <div className="shape-image shape-image-1">
              <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-03-01.png`} alt="Shape Thumb" loading="lazy" />
            </div>
            <div className="shape-image shape-image-2">
              <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-08.png`} alt="Shape Thumb" loading="lazy" />
            </div>
            <div className="shape-image shape-image-3">
              <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-04-01.png`} alt="Shape Thumb" loading="lazy" />
            </div>
            <div className="shape-image shape-image-4">
              <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-03-02.png`} alt="Shape Thumb" loading="lazy" />
            </div>
          </div>
        </div>
      </Element>
    </>
  )
}

export default AboutUsTeam;