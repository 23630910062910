import React, { useState } from 'react';
import SectionTitle from '../sectionTitle/SectionTitle';
import { Element } from 'react-scroll';
// import ScrollAnimation from 'react-animate-on-scroll';
import FsLightbox from 'fslightbox-react';
import { FaPlay } from 'react-icons/fa';
// import { DataTalentCapstoneProjectVideos } from '../../DataTalentCapstoneProjectVideos';

const DataTalentCapstoneProjects = () => {
  const [toggler1, setToggler1] = useState(false);
  const [toggler2, setToggler2] = useState(false);
  const [toggler3, setToggler3] = useState(false);
  // const [toggler4, setToggler4] = useState(false);
  // const [toggler5, setToggler5] = useState(false);
  // const [toggler6, setToggler6] = useState(false);

  const video1 = ['https://www.youtube.com/embed/hGOmAKCI5VY?autoplay=1&rel=0'];
  const video2 = ['https://www.youtube.com/embed/adubOrTZcvc?autoplay=1&rel=0'];
  const video3 = ['https://www.youtube.com/embed/3dM3sSpY9sI?autoplay=1&rel=0'];
  // const video4 = ['https://www.youtube.com/embed/tRDtXcgZaGI?autoplay=1&rel=0'];
  // const video5 = ['https://www.youtube.com/embed/dpXJug2h80g?autoplay=1&rel=0'];
  // const video6 = ['https://www.youtube.com/embed/ZQ2iYJOcyPs?autoplay=1&rel=0'];
  // const [currentVideoID, setCurrentVideoID] = useState();
  // const [updatedArray, setUpdatedArray] = useState(DataTalentCapstoneProjectVideos);

  // const handleToggler = (id) => {
  //   // setToggler( ! toggler );
  //   setCurrentVideoID(id);    
  // }

  // useEffect(() => {
  //   let foundObject = updatedArray.find(item => item.id === currentVideoID);
  //   if (foundObject) {
  //     foundObject.toggler = true;
  //     console.log("found", foundObject);
  //     updatedArray.map(item => (item.toggler = false));
  //     // setUpdatedArray(updatedArray.map(item => (item.id === foundObject.id ? foundObject : item)));
  //   } 
  // }, [currentVideoID, updatedArray])

  return (
    <>
      <Element
        name="CapstoneProjects"
        className="edu-about-area about-style-1 edu-section-gap home-one-cat"
      >
        <div className="container inner">
          <div className="row g-5 align-items-center">
            <div className="col-lg-12">
              <SectionTitle
                classes = "text-center"
                // slogan = "student spotlights"
                title = "Capstone Projects Of Our DataTalent Participants!"
              />
            </div>
            <div className="col-12 col-md-4 mt--40">
              <div className="main-image video-popup-wrapper">
                <img src={`${process.env.PUBLIC_URL}/images/data-talent/capstone-projects/data_analysis_and_manipulation.jpg`} alt="Video PopUp Thumb" loading="lazy" />
                <button className="video-play-btn with-animation position-to-top btn-large video-popup-activation eduvibe-video-play-icon color-secondary" onClick={() => setToggler1( !toggler1 ) }><span><FaPlay className="play-icon" /></span></button>
              </div>
              <div className="video-lightbox-wrapper">
                <FsLightbox
                  toggler={ toggler1 }
                  sources={ [ <iframe src={video1} style={{"width": "960px"}} height="540px" frameBorder="0" title="Youtube video player" allow="autoplay; fullscreen" /> ] }
                  maxYoutubeVideoDimensions={ { width: 960, height: 540 } }
                />
              </div>
              <h5>Data Analysis & Manipulation | Penelope Haro</h5>
            </div>
            <div className="col-12 col-md-4 mt--40">
              <div className="main-image video-popup-wrapper">
                <img src={`${process.env.PUBLIC_URL}/images/data-talent/capstone-projects/data_analysis_and_visualization.jpg`} alt="Video PopUp Thumb" loading="lazy" />
                <button className="video-play-btn with-animation position-to-top btn-large video-popup-activation eduvibe-video-play-icon color-secondary" onClick={() => setToggler2( !toggler2 ) }><span><FaPlay className="play-icon" /></span></button>
              </div>
              <div className="video-lightbox-wrapper">
                <FsLightbox
                  toggler={ toggler2 }
                  sources={ [ <iframe src={video2} style={{"width": "960px"}} height="540px" frameBorder="0" title="Youtube video player" allow="autoplay; fullscreen" /> ] }
                  maxYoutubeVideoDimensions={ { width: 960, height: 540 } }
                  />
              </div>
              <h5>Data Analysis & Visualization | Farzad</h5>
            </div>
            <div className="col-12 col-md-4 mt--40">
              <div className="main-image video-popup-wrapper">
                <img src={`${process.env.PUBLIC_URL}/images/data-talent/capstone-projects/predicting_concrete_strength.png`} alt="Video PopUp Thumb" loading="lazy" />
                <button className="video-play-btn with-animation position-to-top btn-large video-popup-activation eduvibe-video-play-icon color-secondary" onClick={() => setToggler3( !toggler3 ) }><span><FaPlay className="play-icon" /></span></button>
              </div>
              <div className="video-lightbox-wrapper">
                <FsLightbox
                  toggler={ toggler3 }
                  sources={ [ <iframe src={video3} style={{"width": "960px"}} height="540px" frameBorder="0" title="Youtube video player" allow="autoplay; fullscreen" /> ] }
                  maxYoutubeVideoDimensions={ { width: 960, height: 540 } }
                />
              </div>
              <h5>Predicting Concrete Strength | Sola</h5>
            </div>
            {/* <div className="col-12 col-md-6 mt--40">
              <div className="main-image video-popup-wrapper">
                <img src={`${process.env.PUBLIC_URL}/images/data-talent/capstone-projects/pexels-fox-1595391.jpg`} alt="Video PopUp Thumb" loading="lazy" />
                <button className="video-play-btn with-animation position-to-top btn-large video-popup-activation eduvibe-video-play-icon color-secondary" onClick={() => setToggler4( !toggler4 ) }><span><FaPlay className="play-icon" /></span></button>
              </div>
              <div className="video-lightbox-wrapper">
                <FsLightbox
                  toggler={ toggler4 }
                  sources={ [ <iframe src={video4} style={{"width": "960px"}} height="540px" frameBorder="0" title="Youtube video player" allow="autoplay; fullscreen" /> ] }
                  maxYoutubeVideoDimensions={ { width: 960, height: 540 } }
                />
              </div>
              <h5>Data Analysis and Visualization | Lilian Mandirasa</h5>
            </div>
            <div className="col-12 col-md-6 mt--40">
              <div className="main-image video-popup-wrapper">
                <img src={`${process.env.PUBLIC_URL}/images/data-talent/capstone-projects/pexels-fox-1595391.jpg`} alt="Video PopUp Thumb" loading="lazy" />
                <button className="video-play-btn with-animation position-to-top btn-large video-popup-activation eduvibe-video-play-icon color-secondary" onClick={() => setToggler5( !toggler5 ) }><span><FaPlay className="play-icon" /></span></button>
              </div>
              <div className="video-lightbox-wrapper">
                <FsLightbox
                  toggler={ toggler5 }
                  sources={ [ <iframe src={video5} style={{"width": "960px"}} height="540px" frameBorder="0" title="Youtube video player" allow="autoplay; fullscreen" /> ] }
                  maxYoutubeVideoDimensions={ { width: 960, height: 540 } }
                />
              </div>
              <h5>Data Preparation and Visualization on House Sale Prices | Sri Mushnoori</h5>
            </div>
            <div className="col-12 col-md-6 mt--40">
              <div className="main-image video-popup-wrapper">
                <img src={`${process.env.PUBLIC_URL}/images/data-talent/capstone-projects/pexels-fox-1595391.jpg`} alt="Video PopUp Thumb" loading="lazy" />
                <button className="video-play-btn with-animation position-to-top btn-large video-popup-activation eduvibe-video-play-icon color-secondary" onClick={() => setToggler6( !toggler6 ) }><span><FaPlay className="play-icon" /></span></button>
              </div>
              <div className="video-lightbox-wrapper">
                <FsLightbox
                  toggler={ toggler6 }
                  sources={ [ <iframe src={video6} style={{"width": "960px"}} height="540px" frameBorder="0" title="Youtube video player" allow="autoplay; fullscreen" /> ] }
                  maxYoutubeVideoDimensions={ { width: 960, height: 540 } }
                />
              </div>
              <h5>Stock price analysis | Pichayoot Ouppaphan</h5>
            </div> */}
            <div className="view-more-btn text-center">
              <a className="edu-btn" href="https://www.youtube.com/@M2MTechConnect" rel="noreferrer" target="_blank">View More<i className="icon-arrow-right-line-right"></i></a>
            </div>
          </div>
          <div className="shape shape-6 about-parallax-2 d-xl-block d-none">
            <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-12.png`} alt="Shape Thumb" loading="lazy" />
          </div>
        </div>
      </Element>
    </>
  )
}

export default DataTalentCapstoneProjects;